import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { format } from 'date-fns';
import pt from 'date-fns/esm/locale/pt/index.js';
import { useDispatch, useSelector } from 'react-redux';

import { useBudgetRequest } from '~/services/request';
import {
  IconAdd,
  IconAddress,
  IconClose,
  IconCopy,
  IconDate,
  IconDollar,
  IconDownload,
  IconEdit,
  IconMail,
  IconMoney,
  IconNotion,
  IconNotionFilled,
  IconPeople,
  IconWhatsapp,
} from '~/assets/Icons';
import {
  Page,
  ProductList,
  Button,
  Loading,
  Modal,
  Input,
  ModalDefault,
  Return,
  Avatar,
} from '~/components';
import { Buttons } from '~/components/Summary/styles';

import {
  getBudgetRequest,
  getBudgetSuccess,
  situationBudgetRequest,
} from '~/store/modules/budget/actions';
import { IState } from '~/store/modules/types';
import isValidEmail from '~/utils/validate/isValidEmail';

import { situacaoENUM } from '~/constants/situacao';
import { generateRequiredInputValues } from '~/utils/forms';
import { INITIAL_STATE_GET } from '~/store/modules/budget';
import ZoneJustification from './ZoneJustification';
import ModalJustification from './ModalJustification';

import {
  Collum,
  CollumContainer,
  Container,
  ContainerOption,
  Content,
  CreatorBudget,
  ListInfo,
  SubTitle,
  StatusBudget,
  Title,
  Row,
  Responsivenes,
  ButtonBlack,
  ModalMail,
  ContainerButton,
  TitleModal,
  AddEmail,
  ListEmail,
  CollumEmail,
} from './styles';

const Finish: React.FC = () => {
  const data = useSelector((state: IState) => state.budget.data);
  const loading = useSelector((state: IState) => state.budget.loading);
  const [title, setTitle] = useState('');
  const { id } = useParams<{ id?: string }>();
  const history = useHistory();
  const { sendEmail } = useBudgetRequest();
  const [loadingRequest, setLoadingRequest] = useState(true);
  const [loadingParallel, setLoadingParallel] = useState(false);
  const [modalEmail, setModalEmail] = useState(false);
  const [modalJustification, setModalJustification] = useState(false);
  const [isVisibleModalSuccess, setIsVisibleModalSuccess] = useState(false);
  const [isVisibleModalErro, setIsVisibleModalErro] = useState(false);
  const [emails, setEmails] = useState(data.emails);
  const [emailLength, setEmailLength] = useState([1]);
  const [emailState, setEmailState] = useState(1);

  const [inputState, setInputState] = useState<any>({
    ...generateRequiredInputValues([1]),
  });

  const msgLink = `https://dev.waterdesign.opah.com.br/proposal/${data.uuid}`;
  const Link = `<p><h1>Olá, ${data.cliente.nome}, como vai?</h1></p> \n
  <p>Você está recebendo uma proposta comercial de ${data.responsavel.nome}, da Water Design. Estamos ansiosos para participar da construção de um projeto como este!</p>

  <p>Sua proposta encontra-se no link abaixo para análise.</p>

  <p><a href="${msgLink}">VER PROPOSTA</a></p>

  <p>Este é o início de uma grande parceria!</p>

  <p>Atenciosamente, <br>
  Equipe Water Design.</p>`;

  const messageWhatsapp = `Olá, ${data.cliente.nome}, como vai?
  Você está recebendo uma proposta comercial de ${data.responsavel.nome}, da Water Design. Estamos ansiosos para participar da construção de um projeto como este!

  Sua proposta encontra-se no link abaixo para análise.

  ${msgLink}

  Este é o início de uma grande parceria!

  Atenciosamente
  Equipe Water Design.`;

  const dispatch = useDispatch();

  const numberFormat = (e: any) => {
    const value = `${parseFloat(e || 0).toFixed(2)}`.padStart(3, '0');
    const number = value.replace(/\./gi, ',');

    return number;
  };

  useEffect(() => {
    const rascunhoTitle = `${data?.descricao
      .toLowerCase()
      .replace(new RegExp(' ', 'g'), '_')
      .replace(new RegExp('ç', 'g'), 'c')
      .replace(new RegExp('à', 'g'), 'a')
      .replace(new RegExp('á', 'g'), 'a')
      .replace(new RegExp('â', 'g'), 'a')
      .replace(new RegExp('ã', 'g'), 'a')
      .replace(new RegExp('é', 'g'), 'e')
      .replace(new RegExp('ê', 'g'), 'e')
      .replace(new RegExp('í', 'g'), 'i')
      .replace(new RegExp('ó', 'g'), 'o')
      .replace(new RegExp('ô', 'g'), 'o')
      .replace(new RegExp('õ', 'g'), 'o')
      .replace(new RegExp('ú', 'g'), 'u')
      .replace(/[^a-zA-Z0-9_ ]/g, '')}_${new Date(data?.dataCriacao)
      .toLocaleDateString('pt-BR')
      .replace(new RegExp('/', 'g'), '-')}`;

    setTitle(rascunhoTitle);
  }, [data, history]);

  useEffect(() => {
    setTimeout(function () {
      dispatch(getBudgetRequest(id));
    }, 1000);
  }, []);

  useEffect(() => {
    setLoadingRequest(loading);
  }, [loading]);

  useEffect(() => {
    setEmails(data.emails);
  }, [data.emails]);

  // useEffect(() => {
  //   console.log(data.situacao.id);
  //   if (data.situacao.id === 0 || 1) {
  //     history.push(`/budget/edit/${id}`);
  //   }
  // }, []);

  useEffect(() => {
    return () => {
      dispatch(getBudgetSuccess(INITIAL_STATE_GET.data));
    };
  }, []);

  const date = data.dataCriacao ? data.dataCriacao : new Date();

  function addJustification() {
    if (data.justificativa) {
      if (data.justificativa.length > 0) {
        return (
          <ZoneJustification
            data={data.justificativa[0]}
            date={
              data.justificativa[0].dataCriacao
                ? new Date(data.justificativa[0].dataCriacao)
                : new Date()
            }
            onClick={() => setModalJustification(true)}
          />
        );
      }
    }
    return null;
  }

  if (loadingParallel) {
    return <Loading />;
  }
  if (loadingRequest) {
    return <Loading />;
  }

  return (
    <>
      {isVisibleModalErro && (
        <ModalDefault
          success={false}
          onClick={() => setIsVisibleModalErro(false)}
        >
          Ops, algo deu errado...
        </ModalDefault>
      )}
      {isVisibleModalSuccess && (
        <ModalDefault success onClick={() => setIsVisibleModalSuccess(false)}>
          Enviado com sucesso!
        </ModalDefault>
      )}
      {modalJustification && (
        <ModalJustification
          onClick={() => setModalJustification(false)}
          itens={data}
        />
      )}
      {modalEmail && (
        <>
          <Modal>
            <ModalMail>
              <TitleModal>
                <button type="button" onClick={() => setModalEmail(false)}>
                  <IconClose />
                </button>
              </TitleModal>
              <h1>Insira o seu melhor E-mail para enviarmos a proposta.</h1>
              {emailLength.map((key: number) => {
                return (
                  <Input
                    key={key}
                    type="email"
                    required
                    placeholder="example@mail.com"
                    error={inputState[key]?.error}
                    onChange={e => {
                      setInputState((prevState: any) => ({
                        ...prevState,
                        [`${key}`]: {
                          isValid: true,
                          value: e.target.value,
                          required: true,
                          error: !isValidEmail(e.target.value)
                            ? 'Campo inválido'
                            : '',
                        },
                      }));
                    }}
                  />
                );
              })}
              <AddEmail
                onClick={() => {
                  setInputState((prevState: any) => {
                    return {
                      ...prevState,
                      ...generateRequiredInputValues([`${emailState + 1}`]),
                    };
                  });
                  setEmailLength(prevState => [...prevState, emailState + 1]);
                  setEmailState(prevState => prevState + 1);
                }}
              >
                <IconAdd />
                <u>Adicionar e-mail</u>
              </AddEmail>
              <Button
                onClick={() => {
                  let listEmail = '';
                  Object.entries(inputState).forEach((e: any) => {
                    if (listEmail === '') {
                      listEmail = e[1].value;
                      return null;
                    }
                    listEmail = `${listEmail};${e[1].value}`;
                    return null;
                  });

                  try {
                    sendEmail('Proposta WaterDesign', Link, listEmail, id!);
                    setEmails(prevState => `${prevState};${listEmail}`);
                    setIsVisibleModalSuccess(true);
                    setInputState({ ...generateRequiredInputValues([1]) });
                    setEmailLength([1]);
                  } catch {
                    setIsVisibleModalErro(true);
                  }
                  setModalEmail(false);
                }}
              >
                Enviar Email
              </Button>
            </ModalMail>
          </Modal>
        </>
      )}
      <Page>
        <Return />
        <Container column margin>
          <CollumContainer isNot>
            <Title>Orçamento {data.cliente.nome}</Title>
            <ContainerButton mobile width>
              {addJustification()}
            </ContainerButton>

            <ContainerButton>
              <ButtonBlack>
                <Button
                  styles={1}
                  onClick={() => {
                    const inputCopy = document.createElement('input');
                    inputCopy.value = msgLink;
                    document.body.appendChild(inputCopy);
                    inputCopy.select();
                    document.execCommand('copy');
                    document.body.removeChild(inputCopy);
                  }}
                >
                  <IconCopy />
                  Copiar link
                </Button>
                <Button styles={1} onClick={() => setModalEmail(true)}>
                  <IconMail />
                  Enviar proposta por e-mail
                </Button>

                <Button
                  styles={1}
                  onClick={() => {
                    window.open(
                      `https://api.whatsapp.com/send?text=${messageWhatsapp}`,
                      '_blank',
                    );
                  }}
                >
                  <IconWhatsapp />
                  Enviar via WhatsApp
                </Button>
              </ButtonBlack>
            </ContainerButton>

            <Content>
              <span>Resumo da Proposta</span>

              <ListInfo>
                <i>
                  <IconMoney />
                </i>
                <p>
                  Valor Total:
                  <b>
                    {(
                      (data?.precoTotal || 0) / (data.moeda.cotacao || 1)
                    )?.toLocaleString('pt-br', {
                      style: 'currency',
                      currency: data.moeda.codigo || 'BRL',
                    })}
                  </b>
                </p>
              </ListInfo>
              <ListInfo alingItens>
                <i>
                  <IconMoney />
                </i>
                <Collum>
                  <p>
                    Porcentagem de desconto:
                    <b>
                      {numberFormat(data?.desconto)}% (
                      {(
                        data?.valorDesconto / (data.moeda.cotacao || 1)
                      )?.toLocaleString('pt-br', {
                        style: 'currency',
                        currency: data.moeda.codigo || 'BRL',
                      })}
                      )
                    </b>
                  </p>
                  <p>
                    Porcentagem pela mão de obra:
                    <b>
                      {' '}
                      {numberFormat(data?.maoDeObra)}% (
                      {(
                        data?.valorMaoDeObra / (data.moeda.cotacao || 1)
                      )?.toLocaleString('pt-br', {
                        style: 'currency',
                        currency: data.moeda.codigo || 'BRL',
                      })}
                      )
                    </b>
                  </p>
                </Collum>
              </ListInfo>
              <ListInfo>
                <i>
                  <IconMoney />
                </i>
                <p>
                  Valor Final:
                  <b>
                    {(
                      (data.preco || 0) / (data.moeda.cotacao || 1)
                    ).toLocaleString('pt-br', {
                      style: 'currency',
                      currency: data.moeda.codigo || 'BRL',
                    })}
                  </b>
                </p>
              </ListInfo>

              <ListInfo>
                <i>
                  <IconPeople />
                </i>
                <p>
                  Cliente:<b>{data.cliente.nome}</b>
                </p>
              </ListInfo>
              <ListInfo>
                <i>
                  <IconDollar />
                </i>
                <p>
                  Opção de moeda:<b> {data.moeda.nome} </b>
                </p>
              </ListInfo>
              <ListInfo>
                <i>
                  <IconNotion />
                </i>
                <p>
                  Forma de pagamento:
                  <b style={{ whiteSpace: 'pre-wrap' }}>{data.pagamento}</b>
                </p>
              </ListInfo>
              <ListInfo>
                <i>
                  <IconNotion />
                </i>
                <p>
                  Notas sobre a proposta:
                  <b>{data.observacao}</b>
                </p>
              </ListInfo>
              <ListInfo>
                <i>
                  <IconAddress />
                </i>
                <p>
                  Endereço da Obra:
                  <b>
                    {data.endereco.logradouro},{data.endereco.numero} -{' '}
                    {data.endereco.bairro}, {data.endereco.cep},{' '}
                    {data.endereco.cidade}, {data.endereco.estado},{' '}
                    {data.endereco.complemento}
                  </b>
                </p>
              </ListInfo>
            </Content>
          </CollumContainer>
        </Container>

        <ContainerOption>
          <Responsivenes>
            <Button
              onClick={async () => {
                setLoadingParallel(true);
                await dispatch(situationBudgetRequest(data, 'edit'));
                setTimeout(function () {
                  history.replace(`/budget/edit/${data.id}`);
                  setLoadingParallel(false);
                }, 1000);
              }}
            >
              <IconEdit />
              Editar Proposta
            </Button>
            <Buttons>
              <a
                href={`https://dev-water-design-images.s3.us-east-1.amazonaws.com/${title}.pdf`}
                target="_blank"
                rel="noreferrer"
                download
              >
                <Button>
                  <IconDownload />
                  Baixar em PDF
                </Button>
              </a>
            </Buttons>
          </Responsivenes>

          <CreatorBudget>
            <Avatar name={data.criador.nome} />
            <Collum>
              <b>Criador da proposta:</b>
              <p>{data.criador.nome}</p>
            </Collum>
          </CreatorBudget>

          <StatusBudget>
            <Row>
              <IconDate />
              <Collum>
                <b>Criada em:</b>
                <p>
                  {format(new Date(date), "dd 'de' MMMM 'às' HH:mm", {
                    locale: pt,
                  })}
                </p>
              </Collum>
            </Row>
            <Row>
              <IconNotionFilled />
              <Collum>
                <b>Status:</b>
                <p>{situacaoENUM(data.situacao.nome)}</p>
              </Collum>
            </Row>
            <Row>
              <IconMail />
              <CollumEmail>
                <b>Enviado para:</b>
                {emails.split(';').map(e => (
                  <>
                    <ListEmail>{e}</ListEmail>
                    <br />
                  </>
                ))}
              </CollumEmail>
            </Row>
          </StatusBudget>

          <ContainerButton mobile>
            <ButtonBlack>
              <Button
                styles={1}
                onClick={() => {
                  const inputCopy = document.createElement('input');
                  inputCopy.value = Link;
                  document.body.appendChild(inputCopy);
                  inputCopy.select();
                  document.execCommand('copy');
                  document.body.removeChild(inputCopy);
                }}
              >
                <IconCopy />
                Copiar link
              </Button>
              <Button styles={1} onClick={() => setModalEmail(true)}>
                <IconMail />
                Enviar proposta por e-mail
              </Button>

              <Button
                styles={1}
                onClick={() => {
                  window.open(
                    `https://api.whatsapp.com/send?text=${messageWhatsapp}`,
                    '_blank',
                  );
                }}
              >
                <IconWhatsapp />
                Enviar via WhatsApp
              </Button>
            </ButtonBlack>
          </ContainerButton>
          <ContainerButton>
            <span>{addJustification()}</span>
          </ContainerButton>
        </ContainerOption>

        <Container column margin>
          <SubTitle>Produtos Adicionados à Proposta</SubTitle>
          {data.itens.map((product: any) => (
            <>
              <ProductList
                idBudget={id}
                product={product}
                disabled
                key={product.id}
                currency={data.moeda.codigo || 'BRL'}
                quotation={data.moeda.cotacao}
              />
            </>
          ))}
        </Container>
      </Page>
    </>
  );
};

export default Finish;
