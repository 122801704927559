import React, { useCallback, useEffect, useRef, useState } from 'react';

import { useParams } from 'react-router-dom';
import { Button, Input, Modal, ModalDefault, InputPhone } from '~/components';

import { useBudgetRequest } from '~/services/request';
import { api } from '~/services/api';

import Logo from '~/assets/Logotipo/water-design-logo-grande.png';
import { IconAlert, IconClose, IconMenu } from '~/assets/Icons';

import 'react-phone-number-input/style.css';
import { ModalMail } from '~/pages/Budget/Finish/styles';
import isEmailValid from '~/utils/validate/isValidEmail';
import BudgetsService from '~/services/BudgetsService';
import {
  Header,
  JustificationContainer,
  Overlay,
  MenuToShare,
  ToShare,
  ButtonContainer,
  ModalContainer,
  TitleModal,
} from './styles';

const Proposal: React.FC = () => {
  const [menuMobile, setMenuMobile] = useState(false);
  const [modalEmail, setModalEmail] = useState(false);
  const [modalPhone, setModalPhone] = useState(false);
  const [modalEmailToken, setModalEmailToken] = useState(false);
  const [modalJustification, setModalJustification] = useState(false);
  const [modalErro, setModalErro] = useState(false);
  const [modalToken, setModalToken] = useState(false);
  const [modalStatus, setModalStatus] = useState(false);
  const [email, setEmail] = useState('');
  const [emailToken, setEmailToken] = useState('');
  const [emailErro, setEmailErro] = useState('');
  const [phone, setPhone] = useState<any>(undefined);
  const [token, setToken] = useState('');
  const [status, setStatus] = useState({
    message: '',
    erro: false,
  });
  const [justification, setJustification] = useState('');
  const [data, setData] = useState({
    id: '',
    descricao: '',
    dataCriacao: new Date(),
    situacao: {
      id: 0,
    },
    titulo: '',
  });
  const { approveBudget, proposalViewed, requestReview, sendPdfByEmail } =
    useBudgetRequest();
  const ref = useRef<HTMLIFrameElement>(null);
  const { uuid } = useParams<{ uuid?: string }>();
  const [isLoading, setLoading] = useState(false);

  const pullData = useCallback(async () => {
    setLoading(true);
    const response = await api.get(`/budget-api/budget/proposal/${uuid}`);

    const titulo = `${response.data?.descricao
      .toLowerCase()
      .replace(new RegExp(' ', 'g'), '_')
      .replace(new RegExp('ç', 'g'), 'c')
      .replace(new RegExp('à', 'g'), 'a')
      .replace(new RegExp('á', 'g'), 'a')
      .replace(new RegExp('â', 'g'), 'a')
      .replace(new RegExp('ã', 'g'), 'a')
      .replace(new RegExp('é', 'g'), 'e')
      .replace(new RegExp('ê', 'g'), 'e')
      .replace(new RegExp('í', 'g'), 'i')
      .replace(new RegExp('ó', 'g'), 'o')
      .replace(new RegExp('ô', 'g'), 'o')
      .replace(new RegExp('õ', 'g'), 'o')
      .replace(new RegExp('ú', 'g'), 'u')
      .replace(/[^a-zA-Z0-9_ ]/g, '')}_${new Date(response.data?.dataCriacao)
      .toLocaleDateString('pt-BR')
      .replace(new RegExp('/', 'g'), '-')}`;

    setData({
      ...response.data,
      titulo,
    });
    setLoading(false);
  }, [uuid]);

  useEffect(() => {
    pullData();
  }, [uuid, pullData]);

  useEffect(() => {
    if (
      data.situacao.id !== 0 &&
      data.situacao.id !== 3 &&
      data.situacao.id !== 5 &&
      data.situacao.id !== 4 &&
      data.id
    ) {
      proposalViewed(data.id);
    }
  }, [data]);

  const navigatorSayswho = (function (): string {
    const ua = navigator.userAgent;
    let tem;
    const M =
      ua.match(
        /(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i,
      ) || [];
    if (/trident/i.test(M[1])) {
      tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
      return `IE ${tem[1] || ''}`;
    }
    if (M[1] === 'Chrome') {
      tem = ua.match(/\b(OPR|Edg)\/(\d+)/);
      if (tem != null) return tem.slice(1).join(' ').replace('OPR', 'Opera');
    }
    return M[1];
  })();

  return (
    <>
      {modalEmail && (
        <Modal width={window.innerWidth > 800 ? '35vw' : '95%'}>
          <ModalMail>
            <TitleModal>
              <button type="button" onClick={() => setModalEmail(false)}>
                <IconClose />
              </button>
            </TitleModal>
            <h1>Insira o seu melhor E-mail para enviarmos a proposta.</h1>
            <Input
              type="email"
              required
              placeholder="example@mail.com"
              onChange={e => setEmail(e.target.value)}
            />
            <Button
              onClick={async () => {
                try {
                  const response = await sendPdfByEmail(uuid, email);
                  if (!response) {
                    throw new Error('Ops, algo deu errado...');
                  }
                } catch {
                  setStatus({
                    message: 'Ops, algo deu errado...',
                    erro: true,
                  });
                }

                setModalStatus(true);
                setModalEmail(false);
              }}
            >
              Enviar Email
            </Button>
          </ModalMail>
        </Modal>
      )}
      {modalErro && (
        <Modal>
          <ModalContainer>
            <div>
              <i>
                <IconAlert />
              </i>
              {justification.length < 100 ? (
                <>
                  <b>Está faltando algo!</b>
                  <span>
                    Por favor insira a revisão com no minimo 100 caracteres,
                    está faltando <b>{100 - justification.length}</b> caracteres
                  </span>
                </>
              ) : (
                <>
                  <b>Ops...</b>
                  <span>
                    Por favor insira a revisão com no maximo 2000 caracteres,
                    remova <b>{justification.length - 2000}</b> caracteres
                  </span>
                </>
              )}
            </div>
            <Button
              onClick={() => {
                setModalErro(false);
                setJustification('');
              }}
            >
              Entendi!
            </Button>
          </ModalContainer>
        </Modal>
      )}
      {modalPhone && (
        <Modal width={window.innerWidth > 800 ? '35vw' : '95%'}>
          <ModalMail>
            <TitleModal>
              <button type="button" onClick={() => setModalPhone(false)}>
                <IconClose />
              </button>
            </TitleModal>
            <h1>Insira o número de telefone para confirmar a proposta.</h1>
            <InputPhone onChange={e => setPhone(e)} value={phone} />

            <Button
              onClick={async () => {
                try {
                  await approveBudget({
                    id: data.id,
                    telefone: phone.toString(),
                  });
                  setModalToken(true);
                } catch {
                  setStatus({
                    message: 'Ops, algo deu errado...',
                    erro: true,
                  });
                  setModalStatus(true);
                }
                setModalPhone(false);
              }}
            >
              Enviar token
            </Button>
            <Button
              styles={1}
              onClick={async () => {
                setModalEmailToken(true);
                setModalPhone(false);
              }}
            >
              Receber token por e-mail
            </Button>
          </ModalMail>
        </Modal>
      )}
      {modalEmailToken && (
        <Modal width={window.innerWidth > 800 ? '35vw' : '95%'}>
          <ModalMail>
            <TitleModal>
              <button type="button" onClick={() => setModalPhone(false)}>
                <IconClose />
              </button>
            </TitleModal>
            <h1>Insira o seu melhor E-mail para confirmar a proposta.</h1>
            <Input
              type="email"
              required
              placeholder="example@mail.com"
              error={emailErro}
              onChange={e => {
                setEmailToken(e.target.value);
                if (!isEmailValid(e.target.value)) {
                  setEmailErro('E-mail inválido');
                }
                if (isEmailValid(e.target.value)) {
                  setEmailErro('');
                }
              }}
            />
            <Button
              onClick={async () => {
                try {
                  await BudgetsService.sendToken({
                    id: data.id,
                    email: emailToken,
                  });
                  setModalToken(true);
                } catch {
                  setStatus({
                    message: 'Ops, algo deu errado...',
                    erro: true,
                  });
                  setModalStatus(true);
                }
                setModalEmailToken(false);
              }}
            >
              Enviar token
            </Button>
            <Button
              styles={1}
              onClick={async () => {
                setModalPhone(true);
                setModalEmailToken(false);
              }}
            >
              Receber token pelo celular
            </Button>
          </ModalMail>
        </Modal>
      )}
      {modalToken && (
        <Modal width={window.innerWidth > 800 ? '35vw' : '95%'}>
          <ModalMail>
            <TitleModal>
              <button type="button" onClick={() => setModalToken(false)}>
                <IconClose />
              </button>
            </TitleModal>
            <h1>
              Insira o token que você recebeu no seu celular para confirmar a
              proposta.
            </h1>
            <Input
              required
              maxLenght={6}
              onChange={e => setToken(e.target.value)}
            />
            <Button
              onClick={async () => {
                setModalPhone(false);
                try {
                  await BudgetsService.approveToken({ id: data.id, token });
                  await pullData();
                  setStatus({
                    message: 'Proposta aprovada com sucesso!',
                    erro: false,
                  });
                  setModalStatus(true);
                } catch {
                  setStatus({
                    message: 'Ops, algo deu errado...',
                    erro: true,
                  });
                  setModalStatus(true);
                }
                setModalToken(false);
              }}
            >
              Confirmar Proposta
            </Button>
          </ModalMail>
        </Modal>
      )}
      {modalStatus && (
        <ModalDefault
          onClick={() => setModalStatus(false)}
          success={!status.erro}
        >
          {status.message}
        </ModalDefault>
      )}
      <div style={{ height: '100vh' }}>
        <Header>
          <img src={Logo} alt="Logo Waterdesing" />
          <ButtonContainer state={data.situacao.id === 5}>
            <Button
              styles={modalJustification ? 1 : null}
              onClick={async () => {
                setModalPhone(true);
              }}
            >
              Aprovar o Orçamento
            </Button>
          </ButtonContainer>
          {navigatorSayswho.toLocaleLowerCase() === 'safari' && (
            <a
              href={`https://dev-water-design-images.s3.us-east-1.amazonaws.com/${data.titulo}.pdf`}
            >
              <Button styles={1}>Imprimir</Button>
            </a>
          )}
          <Button
            styles={modalJustification ? null : 1}
            onClick={() => setModalJustification(!modalJustification)}
          >
            Solicitar revisão
          </Button>
          <ToShare onClick={() => setMenuMobile(!menuMobile)}>
            <IconMenu />
          </ToShare>
        </Header>
        {modalJustification && (
          <>
            <hr />
            <JustificationContainer>
              <span>Descreva a justificativa de sua solicitação</span>
              <p>Min. 100 / Máx. 2000 carcacteres</p>
              <textarea
                minLength={100}
                maxLength={2000}
                required
                onChange={e => setJustification(e.target.value)}
              />
              <div>
                <Button
                  // eslint-disable-next-line consistent-return
                  onClick={() => {
                    if (
                      justification.length < 100 ||
                      justification.length > 2000
                    ) {
                      setModalErro(true);
                    } else {
                      try {
                        requestReview(data.id, justification);
                        setStatus({
                          message: 'Justificativa enviada com sucesso!',
                          erro: false,
                        });
                        setModalStatus(true);
                      } catch {
                        setStatus({
                          message: 'Ops, algo deu errado...',
                          erro: true,
                        });
                        setModalStatus(true);
                      }
                      setModalJustification(false);
                    }
                  }}
                >
                  Enviar
                </Button>
                <Button
                  styles={1}
                  onClick={() => {
                    setModalJustification(false);
                  }}
                >
                  Voltar
                </Button>
              </div>
            </JustificationContainer>
            <Overlay />
          </>
        )}
        {menuMobile && (
          <MenuToShare>
            <ButtonContainer state={data.situacao.id === 5}>
              <Button
                onClick={async () => {
                  setModalPhone(true);
                }}
              >
                Aprovar o Orçamento
              </Button>
            </ButtonContainer>
            {navigatorSayswho.toLocaleLowerCase() === 'safari' && (
              <a
                href={`https://dev-water-design-images.s3.us-east-1.amazonaws.com/${data.titulo}.pdf`}
              >
                <Button styles={1}>Imprimir</Button>
              </a>
            )}
            {
              <Button
                styles={1}
                onClick={async () => {
                  if (!navigator.canShare) {
                    setStatus({
                      message:
                        'Ops, o navegador não suporta a opção de compartilhamento...',
                      erro: true,
                    });
                    setModalStatus(true);
                  } else {
                    const option = {
                      method: 'HEAD',
                    };
                    const response = await fetch(
                      `https://dev-water-design-images.s3.us-east-1.amazonaws.com/${data.titulo}.pdf`,
                      option,
                    );
                    const buffer = await response.arrayBuffer();

                    const pdf = new File([buffer], `${data.titulo}.pdf`, {
                      type: 'application/pdf',
                    });
                    const files = [pdf];

                    // Share PDF file if supported.
                    if (navigator.canShare({ files })) {
                      await navigator.share({ files });
                    }
                  }
                }}
              >
                Compartilhar
              </Button>
            }
            <Button styles={1} onClick={() => setModalJustification(true)}>
              Solicitar revisão
            </Button>
          </MenuToShare>
        )}
        {!isLoading && (
          <iframe
            ref={ref}
            style={{
              width: '100%',
              height: '83.4vh',
              border: 0,
            }}
            title="Proposta"
            src={`https://dev-water-design-images.s3.us-east-1.amazonaws.com/${data.titulo}.pdf`}
          />
        )}
      </div>
    </>
  );
};

export default Proposal;
